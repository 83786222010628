import { Collection } from '@api/orderbook_api/v1/types.pb';
import { Flex, FormatCryptoCurrency, Text } from '@components/primitives';
import { getZeroAddress } from '@utils/paymentTokens';
import { PropsWithChildren, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

interface Props {
  collection?: Collection;
}

const Metric = ({ label, children }: PropsWithChildren<{ label: string }>) => (
  <Flex direction='column' css={{ gap: '$1' }}>
    <Text
      color='subtle'
      css={{
        fontSize: 12,
        fontWeight: 500,
        '@lg': {
          fontSize: 13,
        },
      }}
    >
      {label}
    </Text>
    {children}
  </Flex>
);

const priceCss = {
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 1,
  '@lg': {
    fontSize: 15,
    fontWeight: 600,
  },
};

export const useCollectionMetrics = ({ collection }: Props) => {
  const isSmallDevice = useMediaQuery({ maxWidth: 1255 });

  if (!collection) {
    return {} as any;
  }

  const metrics = useMemo(() => {
    const { stats } = collection;
    const zeroSymbol = getZeroAddress(Number(collection?.chainId))?.symbol;

    const floorPrice = (
      <Metric label='Floor Price'>
        <FormatCryptoCurrency
          css={priceCss}
          amount={collection?.stats?.floorAsk?.price?.amount?.raw}
          symbol={collection?.stats?.floorAsk?.price?.currency?.symbol}
          maximumFractionDigits={2}
          decimals={collection?.stats?.floorAsk?.price?.currency?.decimals}
          small={isSmallDevice}
        ></FormatCryptoCurrency>
      </Metric>
    );

    const topOffer = (
      <Metric label='Top Offer'>
        <FormatCryptoCurrency
          css={priceCss}
          amount={collection?.stats?.topOffer?.price?.amount?.raw}
          symbol={collection?.stats?.topOffer?.price?.currency?.symbol}
          maximumFractionDigits={2}
          decimals={collection?.stats?.floorAsk?.price?.currency?.decimals}
          small={isSmallDevice}
        ></FormatCryptoCurrency>
      </Metric>
    );

    const vol24 = (
      <Metric label='24h Vol'>
        <FormatCryptoCurrency
          css={priceCss}
          amount={collection?.stats?.volume?.oneDay}
          symbol={zeroSymbol}
          maximumFractionDigits={2}
          small={isSmallDevice}
        ></FormatCryptoCurrency>
      </Metric>
    );

    const volAll = (
      <Metric label='All Vol'>
        <FormatCryptoCurrency
          css={priceCss}
          symbol={zeroSymbol}
          amount={collection?.stats?.volume?.allTime}
          maximumFractionDigits={2}
          small={isSmallDevice}
        ></FormatCryptoCurrency>
      </Metric>
    );

    const { totalListings } = stats as any;
    const { totalSupply } = collection;
    const listedSupply = (
      <Metric label='Listed'>
        <Text css={priceCss}>
          {totalListings ?? '-'} / {totalSupply ?? '-'}
        </Text>
      </Metric>
    );

    const owners = (
      <Metric label='Owners'>
        <Text css={priceCss}>{stats?.ownerCount ?? '-'}</Text>
      </Metric>
    );

    const countSales24h = (
      <Metric label='24h Sales'>
        <Text css={priceCss}>{stats?.totalSalesOneDay ?? '-'}</Text>
      </Metric>
    );

    const avgSale24h = (
      <Metric label='24h Sales'>
        <FormatCryptoCurrency
          css={priceCss}
          amount={collection?.stats?.averages?.salePriceOneDay}
          symbol={zeroSymbol}
          maximumFractionDigits={2}
          small={isSmallDevice}
        ></FormatCryptoCurrency>
      </Metric>
    );

    return {
      floorPrice,
      topOffer,
      vol24,
      volAll,
      listedSupply,
      owners,
      countSales24h,
      avgSale24h,
    };
  }, [collection, isSmallDevice]);

  return metrics;
};
